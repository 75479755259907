<template>
  <div>
    <div v-if="dis == 99">
      <success></success>
    </div>
    <div v-else-if="dis == 1">
      <add-list :group="formlist" :ruleForm="grouplist" @changedis="changedis"></add-list>
    </div>
    <div v-else>
      <div v-if="datalist.title!=null">
        <van-row>
          <van-col span="20" class="mt5 mright mr10">
            <span class="txt">{{userinfo.nickname}}</span>
          </van-col>
          <van-col span="2" class="mt4 mr10">
            <van-image width="2rem" height="2rem" round :src="userinfo.headimgurl"></van-image>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="16" offset="1">{{datalist.title}}</van-col>
        </van-row>
        <div class="line"></div>
        <van-form @submit="onSubmit" @failed="failSub">
          <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
            <van-row class="mb10">
              <van-col span="19" offset="1">{{item.title}}</van-col>
              <van-col span="4" v-if="item.type == 'list'" @click="addData(item)">
                <van-icon name="add" color="#1989F0"/><span style="color: #1989f0">添加</span>
              </van-col>
            </van-row>
            <div v-if="item.type == 'data'">
              <form-display :group="item.fields" :ruleForm="ruleForm"></form-display>
            </div>
            <div v-if="item.type == 'list' && listinfo.length != 0">
              <div v-for="(it,ke) in listinfo" :key="ke" class="mt10 font13">
                <van-row>
                  <van-col span="20" offset="1" class="mt1" v-for="ite in item.fields" :key="ite.name" @click="toUpdate(item,ke,it)">
                    <span class="mr10">{{ ite.title }}：</span>
                    <span  class="" v-if="ite.type=='select'">{{ it[ite.name].label }}</span>
                    <span  class="" v-else-if="ite.type=='mutiselect'">
                    <span v-for="(muti,k) in it[ite.name]" :key="k" >{{ muti.label }}，</span>
                  </span>
                    <span  class="" v-else>{{ it[ite.name] }}</span>
                  </van-col>
                  <van-col span="3" @click="deleteItem(it,ke)" class="col19">删除</van-col>
                </van-row>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>

import qs from 'qs';
import {get} from '@/utils/request.js';
import { Toast } from 'vant';
import { submitForm } from "@/api/api.js";
import mixins from '@/mixins'
import formDisplay from "@/components/formDisplay";
import addList from "@/components/addList";
import success from "@/components/success";

export default {
  name: 'Home',
  mixins:[mixins],
  components:{
    formDisplay,
    addList,
    success,
  },
  data(){
    return{
      radio:"",
      checkboxGroup: [],
      result:[],
      title:'',
      ruleForm:{
        user:"",
        taskId: {},
      },//提交数据
      list:{
        field:""
      },
      datalist: "",//获取用户需要填写的表单数据
      userinfo:{},//用户信息
      taskid:"",//提交时需要的taskid
      datainfo:{},//提交之后重新加载获取的data中的数据
      code:"",//111
      state:"",//af1543f0063e4aeab32e30d830daade0
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      leng:0,//check的长度
      /*timeitem:"",//
      pattern:/^[1][3,4,5,7,8][0-9]{9}$/,*/
      datas: {},
      listinfo: [],//存储列表里添加的数据//更改单选多选后的数据
      listinfos: [],//存储列表里添加的未更改的数据
      formlist: [],//当前要修改的列表的表单数据
      grouplist: {},//当前要修改的列表数据
      nowkey: 0,//当前要修改的列表数据的位置
      dis: 0,//
      txt:"5.《中国共产党党员教育管理工作条例》规定，基层党组织应当加强流动党员管理，对外出（ ）以上并且没有转移组织关系的流动党员，应当保持经常联系，跟进做好教育培训、管理服务等工作（5分）"
    }
  },
  created(){

  },
  mounted() {
    let flag = this.isWeixin();
    if (flag){
      let local = localStorage.getItem('listinfo')
      if (local!=null&&local!=undefined&&local!=''){
        localStorage.removeItem('listinfo');
      }else {
        localStorage.removeItem('listinfo');
      }
      this.getCode();
      this.listinfo = [];
    }else {
      this.$router.push('notwx');
    }
    /*this.getCode();
    localStorage.removeItem('listinfo');
    this.datalist = JSON.parse(this.getForm().formDef);
    console.log("form",this.datalist);
    this.datainfo = {
      FiledStatus: "FILED",
      appId: "0f9230feb4b4cb730406898109a4199e",
      dataId: "b521cc9858934fe786951422982d5e32",
      field102: "测试文本内容",
      field103: [
          {
        field104: "测试列表文本",
        field105: 2,
        field106: "2022-01-14",
        field107: {label: "选项2", value: "2"},
        field108: [{label: "选项2还好", value: "2"}, {label: "选项3哈哈", value: "3"}]
      },
        {
          field104: "默认值22",
          field105: 5,
          field106: "2022-01-17",
          field107: {label: "选项3", value: "3"},
          field108: [{label: "选项4很慌", value: "4"}, {label: "选项1换行", value: "1"}]
        }
      ],
      mAppId: "0f9230feb4b4cb730406898109a4199e",
      mdfuser: {nickName: "陈倩", userId: "e49bf27c391d43b8b7f91761d4021734"},
      root: 1,
      ts: 1641867501108,
      upDataId: "b521cc9858934fe786951422982d5e32",
      _id: {$oid: "46edde34bbe6705f5d000040"},
    };
    this.listinfo = [];
    this.getInfo();
    if (this.datalist.groups.length!=0){
      this.setRuleForm(this.list)
    }
    if (this.datainfo!=null && this.datainfo != ''){
      this.setRuleForm(this.list)
    }*/
  },
  methods:{
    getCode(){
      /*let href = "https://sixu.work/task?code=021YK9000SFX6N15P5100ldnZS1YK90m&state=1ac3c1bfc250495ca2399a56f41a3f4f"
      if (href.indexOf("state") != -1){
        this.code= qs.parse(href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(href.split("#")[0].split("?")[1]).state;
        console.log("code",this.code);
        console.log("state",this.state);
      }*/
      if (window.location.href.indexOf("state") != -1){
        this.code = qs.parse(window.location.href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(window.location.href.split("#")[0].split("?")[1]).state
      }
      if (this.code){
        //调用接口，获取用户信息,获取到openid当作token存入本地存储中
        //localStorage.setItem(token, JSON.stringify(tokenObj))
        get('/sso/auth/authwx/'+this.code+'/'+this.state).then(res=>{
        if (res!=null&&res.data!=null&&res.data.content!=null){
          let contents = res.data.content;
          //console.log('forms',contents);
          this.userinfo = contents.user;
          localStorage.removeItem("token")
          localStorage.setItem("token",this.userinfo.openid);
          this.taskid = contents.taskId;
          this.datalist = JSON.parse(contents.form.formDef);
          //console.log('form',this.datalist);

          let groups = this.datalist.groups;
          if (groups!=null&&groups!=''&&groups.length!=0){
            this.setRuleForm(this.list);
          }
          if(contents.data!=null&&contents.data!=''){
            this.datainfo = contents.data;
            this.setRuleForm(this.list);
          }
        }else {
          Toast.fail(res.data.errorMsg);
        }
       })
      }
    },
    //赋值ruleForm
    setRuleForm(vdata){
      let data={};//
      if (this.datalist.groups){
        for(let i in this.datalist.groups){
          let group=this.datalist.groups[i];
          //data[group.name]=group.title;
          if (group.fields){
            if(group.type=="data"||group.type == 'list'){
              for (let j in group.fields){
                let item = group.fields[j];
                if (item.type == "date") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDate(this.currentDate);
                    //console.log('1',data[item.name])
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "time") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.currentTime;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "datetime") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDateTime(this.currentDateTime);
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
                  if (item.source.default !=null && item.source.default !=''){
                    data[item.name] = item.source.default;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else {
                  data[item.name]=vdata[item.name];
                }
                if (this.datainfo!=null&&this.datainfo!=''&&this.datainfo.dataId!=null){
                  let k = item.name;
                  if (item.type == "date"){
                    this.date = this.datainfo[k];
                    //this.currentDate = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "select") {
                    if (this.datainfo[k]!=null&&this.datainfo[k]!=''){
                      data[item.name] = this.datainfo[k].value;
                    }
                  }else if (item.type == "mutiselect") {
                    data[item.name] = [];
                    for (let aa in this.datainfo[k]){
                      data[item.name].push(this.datainfo[k][aa].value)
                    }
                  }else if (item.type == "time") {
                    this.currentTime = this.datainfo[k];
                    this.time = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "datetime") {
                    //this.currentDateTime = this.datainfo[k];
                    this.datetime = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else {
                    data[item.name] = this.datainfo[k];
                  }
                }
              }
            }
          }
          if (group.type == 'list'){
            if (this.datainfo[group.name]){
              this.listinfo = this.datainfo[group.name];
              this.listinfos = this.listinfo;
              localStorage.removeItem('listinfo')
              const data = this.listinfos;
              const datas = JSON.stringify(data);
              localStorage.setItem('listinfo',datas);
            }
          }
        }
      }
      this.ruleForm = data;
      //this.errmsg = data;
      //console.log("shujusss",this.ruleForm);
    },
    addData(item){
      this.formlist = [];
      this.formlist = item;
      this.setRuleForm(this.list);
      this.grouplist = {};
      this.grouplist = this.ruleForm;
      this.nowkey = null;
      this.dis = 1;
    },
    //获取添加的列表的数据
    getInfo(){
      if (localStorage.getItem('listinfo')!=null && localStorage.getItem('listinfo')!=undefined){
        this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
        this.listinfos = JSON.parse(localStorage.getItem('listinfo'));

        for (let n in this.listinfo){
          let info = this.listinfo[n];
          for (let k in this.datalist.groups){
            let group = this.datalist.groups[k];
            if (group.type == 'list'){
              for (let k in group.fields){
                let sou = group.fields[k];
                  if (sou.type == 'select'){
                    if (info[sou.name]){
                      let da = info[sou.name];
                      let dat = sou.source.data;
                    for (let m in dat){
                      if (da == dat[m].value){
                        info[sou.name]= dat[m];
                      }
                    }
                  }
                }else if (sou.type == 'mutiselect'){
                    let datas = [];
                  if (info[sou.name]){
                    for (let p in info[sou.name]){
                      let da = info[sou.name][p];
                      if (da.label){
                        da = info[sou.name][p];
                      }else {
                        let dat = sou.source.data;
                        for (let d in dat){
                          if (da == dat[d].value){
                            datas.push(dat[d]);
                          }
                        }
                      }
                    }
                    info[sou.name] = datas;
                  }
                }
              }
            }
          }
        }
      }
      //console.log("list",this.listinfo);
    },
    toUpdate(item,ke,itt){
      this.formlist = [];
      this.formlist = item;
      this.grouplist = {};
      let i = itt;
      for (let z in item.fields){
        let its = item.fields[z];
        if (its.type == 'select'){
          if (i[its.name]){
            i[its.name] = i[its.name].value;
          }
        }else if (its.type == 'mutiselect'){
          if (i[its.name].length != 0){
            for (let a in i[its.name]){
              let it = i[its.name][a];
              i[its.name][a] = it.value;
            }
          }
        }
      }
      this.grouplist = i;
      let da = {key: ke}
      this.$store.commit('addlist/CLEAR_ADDINFO');
      this.$store.commit('addlist/SET_ADDINFO',da);
      //console.log("thke",this.nowkey)
      this.dis = 1;
    },
    deleteItem(it,ke){
      this.listinfo.splice(ke,1);
      this.listinfos.splice(ke,1);
      let data = JSON.stringify(this.listinfos)
      localStorage.removeItem('listinfo');
      localStorage.setItem('listinfo',data);
    },
    onSubmit(){//提交表单
      let values = [];
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        if (grou.type == 'list'){
          this.ruleForm[grou.name] = [];
          this.ruleForm[grou.name] = this.listinfo;
          for (let l in grou.fields){
            let lit = grou.fields[l];
            delete this.ruleForm[lit.name];
          }
        }else {
          for(let n in grou.fields){
            let items= grou.fields[n];
            if (items.type=="select"){
              if (this.ruleForm[items.name]){
                let valu = this.ruleForm[items.name];
                let datas = items.source.data
                if (datas!=null&&datas.length>0){
                  for (let m in datas){
                    let val=datas[m];
                    if (val.value==valu){
                      this.ruleForm[items.name]=val;
                    }
                  }
                }
              }
            }
            if (items.type=="mutiselect"){
              if (this.ruleForm[items.name]){
                for(let i in this.ruleForm[items.name]){
                  let val = this.ruleForm[items.name][i];
                  if (items.source.data!=null&&items.source.data!=''){
                    for (let j in items.source.data){
                      let vall = items.source.data[j];
                      if (val == vall.value){
                        values.push(vall) ;
                      }
                    }
                  }
                }
              }
              this.ruleForm[items.name] = values
            }
          }
        }
      }
      this.ruleForm.user=this.userinfo;
      this.ruleForm.taskId = this.taskid;
      //console.log("ruleForm",this.ruleForm);
      submitForm(this.ruleForm).then(res=>{
        if (res!=null&&res.data!=null&&res.data.content!=null){
          if (res.data.content==1){
            Toast.success('提交成功');
            this.lowering(this.ruleForm);
            //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ef0e4fab3c1525d&redirect_uri=https%3A%2F%2Fsixu.work%2Ftask&response_type=code&scope=snsapi_userinfo&state="+this.state+"#wechat_redirect"
            localStorage.removeItem('listinfo');
            this.dis = 99;
          }
        }else {
          Toast.fail(res.data.errorMsg);
          this.lowering(this.ruleForm);
        }
      });
    },
    lowering(da){
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        for(let n in grou.fields){
          let items= grou.fields[n];
          if (items.type=="select"){
            if (da[items.name] != null && da[items.name] != ''){
              da[items.name] = da[items.name].value;
            }
          }
          if (items.type=="mutiselect"){
            let values = [];
            if (da[items.name]){
              for(let i in da[items.name]){
                values.push(da[items.name][i].value);
              }
            }
            da[items.name] = values
          }
        }
      }
      //console.log("daaaa",da)
      //return da;
    },
    failSub(){
      Toast("请检查必填项是否全部填写！")
    },
    changedis(d){
      this.dis = d;
      this.getInfo();
    }

  },
}
</script>

<style lang="less" scoped>
.wid{width: 100%;}
.mt1{margin-top: 5px;}
.mb1{margin-bottom: 5px;}
.mt3{margin-top: 3vh;}
.mt4{margin-top: 4vh;}
.mt5{margin-top: 5vh;}
.mt20{margin-top: 2vh;}
.mt10{margin-top: 10px;}
.ml15{margin-left: 15px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb10{margin-bottom: 1vh;}
.font13{font-size: 13px;}
.line1{border-top: #f2f2f2 solid 1px;width: 88vw;margin: 8px auto;}
.line{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}
.mr{margin-right: 18vw;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.mcenter{text-align: center;}
.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
/deep/ .van-cell{padding: 4px 16px;}
.col19{color: #1989f0;}
</style>
